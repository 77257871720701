import { toast } from "react-toastify";
import JsonInterceptor from "../../../utils/JsonInterceptor";
import { API_END_POINTS } from "../../../utils/config";
import { EventType } from "./type";

export const storeTickets = (data) => async (dispatch) => {
  try {
    return dispatch({
      type: EventType.STORE_TICKET_DATA,
      payload: data
    });
  } catch (error) {
    // console.log(error);
  }
};

export const createTicket = (body) => async (dispatch) => {
  try {
    dispatch({ type: EventType.CREATE_TICKET_REQUEST });
    let apiEndpoint = `${API_END_POINTS.createTicket}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: EventType.CREATE_TICKET_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.CREATE_TICKET_FAIL, error: message });
  }
};

export const updateTicket = (body) => async (dispatch) => {
  try {
    dispatch({ type: EventType.UPDATE_TICKET_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateTicket}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: EventType.UPDATE_TICKET_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.UPDATE_TICKET_FAIL, error: message });
  }
};

export const updateTicketStatus = (body) => async (dispatch) => {
  try {
    dispatch({ type: EventType.UPDATE_TICKET_STATUS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateTicketStatus}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: EventType.UPDATE_TICKET_STATUS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.UPDATE_TICKET_STATUS_FAIL, error: message });
  }
};

export const getAgeGroup = () => async (dispatch) => {
  try {
    dispatch({ type: EventType.GET_AGE_GROUP_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getAgeGroup}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);

    return dispatch({
      type: EventType.GET_AGE_GROUP_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error?.data?.message ? error?.data?.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.GET_AGE_GROUP_FAIL, error: message });
  }
};

export const getOrganizerDropDown = () => async (dispatch) => {
  try {
    dispatch({ type: EventType.GET_AGE_GROUP_REQUEST });
    let apiEndpoint = `${API_END_POINTS.organizerDropDown}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: EventType.GET_AGE_GROUP_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error?.data?.message ? error?.data?.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.GET_AGE_GROUP_FAIL, error: message });
  }
};

export const deleteSubSlot = (body) => async (dispatch) => {
  try {
    dispatch({ type: EventType.DELETE_SUB_SLOT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteSlot}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: EventType.DELETE_SUB_SLOT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.DELETE_SUB_SLOT_FAIL, error: message });
  }
};

export const reorderTicket = (body) => async (dispatch) => {
  try {
    dispatch({ type: EventType.CHANGE_TICKET_ORDER_REQUEST });
    let apiEndpoint = `${API_END_POINTS.changeTicketOrder}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: EventType.CHANGE_TICKET_ORDER_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.CHANGE_TICKET_ORDER_FAIL, error: message });
  }
};

export const syncWithSeatIO = (id) => async (dispatch) => {
  try {
    dispatch({ type: EventType.SYNC_WITH_SEAT_IO_REQUEST });
    let apiEndpoint = `${API_END_POINTS.syncWithSeatIo}/${id}`;

    try {
      const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
      return dispatch({
        type: EventType.SYNC_WITH_SEAT_IO_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: EventType.SYNC_WITH_SEAT_IO_FAIL, error: message });
  }
};
