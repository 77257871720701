import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = () => ({
  grey: {
    100: "#141414",
    200: "#292929",
    300: "#3d3d3d",
    400: "#525252",
    500: "#666666",
    600: "#858585",
    700: "#a3a3a3",
    800: "#c2c2c2",
    900: "#e0e0e0",
    50: "rgba(245, 245, 245, 0.5)",
    80: "rgba(245, 245, 245)"
  },
  primary: {
    100: "#ffcdd2",
    200: "#ef9a9a",
    300: "#0c101b",
    400: "#ffffff", // manually changed
    500: "#ef5350",
    600: "#f44336",
    700: "#4c8ac7", // theme secondary color
    800: "#2b6aaa", // theme tertiary color
    900: "#253b74", // theme primary color
    50: "rgba(37, 59, 116 , 0.5)"
  },
  white: {
    900: "#ffffff"
  },
  black: {
    900: "#000000",
    50: "rgb(30 31 33 / 50%)"
  },
  success: {
    400: "#3d6b98",
    900: "#1d9142"
  },
  danger: {
    800: "#b06668",
    900: "#d01d23"
  },
  warning: {
    900: "#f3863e"
  },
  grayBg: {
    900: "#fcfcfc"
  },
  iconGrey: {
    900: "#757575"
  },
  yellow: {
    900: "#ffc107"
  },
  created: {
    900: "#6c757d"
  },
  awaiting_response: {
    900: "#F58C7A"
  },
  under_review: {
    900: "#3d6b98"
  },
  solved: {
    900: "#709a7d"
  },
  link: {
    900: "#126efd"
  },
  blue: {
    100: "#008ffb"
  }
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[900],
              light: colors.primary[800]
            },

            secondary: {
              main: colors.grey[100]
            },
            danger: {
              main: colors.danger[900]
            },
            background: {
              default: colors.primary[300]
            },
            yellow: { main: colors.yellow[900] },
            info: { main: colors.blue[100] },
            dashboard: {
              border: colors.black[50]
            }
          }
        : {
            // palette values for light mode
            info: { main: colors.blue[100] },
            primary: {
              main: colors.primary[900],
              light: colors.primary[800]
            },

            secondary: {
              main: colors.grey[300]
            },
            danger: {
              main: colors.danger[900]
            },
            background: {
              default: "#fcfcfc"
            },
            yellow: { main: colors.yellow[900] },
            dashboard: {
              border: colors.grey[50]
            }
          })
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1800
      }
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            background: "#fff"
          }
        }
      }
    }
  };
};

// ? context for color mode dark/light
export const ColorModeContext = createContext({
  toggleColorMode: () => {}
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  useEffect(() => {
    if (typeof window !== "undefined") {
      setMode(window.localStorage.getItem("theme") || "light");
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        window.localStorage.setItem("theme", newMode);
      }
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
