import { EventType } from "./type";

// Auth intial state
const initialAuthState = {
  tickets: [],
  ticketsData: [],
  organizerDropdown: [],
  ageGroup: [],
  isLoading: true,
  isUpdateTicketLoading: false,
  reorderedData: null,
  ReorderLoad: false,
  isSyncing: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case EventType.STORE_TICKET_DATA:
      return { ...state, isLoading: false, ticketsData: action.payload };
    case EventType.CREATE_TICKET_REQUEST:
      return { ...state, isLoading: true };
    case EventType.CREATE_TICKET_SUCCESS:
      return { ...state, isLoading: false, tickets: action.payload.data };
    case EventType.CREATE_TICKET_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_TICKET_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_TICKET_SUCCESS:
      return { ...state, isLoading: false, tickets: action.payload.data };
    case EventType.GET_TICKET_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.UPDATE_TICKET_REQUEST:
      return { ...state, isUpdateTicketLoading: true };
    case EventType.UPDATE_TICKET_SUCCESS:
      return { ...state, isUpdateTicketLoading: false, tickets: action.payload.data };
    case EventType.UPDATE_TICKET_FAIL:
      return { ...state, isUpdateTicketLoading: false, error: action.error };
    case EventType.GET_ORGANIZER_DROPDOWN_REQUEST:
      return { ...state, isLoading: true, error: action.error };
    case EventType.GET_ORGANIZER_DROPDOWN_SUCCESS:
      return { ...state, isLoading: false, organizerDropdown: action.payload.data };
    case EventType.GET_ORGANIZER_DROPDOWN_FAIL: {
      return { ...state, isLoading: false };
    }
    case EventType.CHANGE_TICKET_ORDER_REQUEST:
      return { ...state, ReorderLoad: true, error: action.error };
    case EventType.CHANGE_TICKET_ORDER_SUCCESS:
      return { ...state, ReorderLoad: false, reorderedData: action.payload.data };
    case EventType.CHANGE_TICKET_ORDER_FAIL:
      return { ...state, ReorderLoad: false };
    case EventType.GET_AGE_GROUP_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_AGE_GROUP_SUCCESS:
      return { ...state, isLoading: false, ageGroup: action.payload.data };
    case EventType.GET_AGE_GROUP_FAIL:
      return { ...state, isLoading: false };
    case EventType.SYNC_WITH_SEAT_IO_REQUEST:
      return { ...state, isSyncing: true };
    case EventType.SYNC_WITH_SEAT_IO_SUCCESS:
      return { ...state, isSyncing: false };
    case EventType.SYNC_WITH_SEAT_IO_FAIL:
      return { ...state, isSyncing: false };
    default:
      return state;
  }
};
