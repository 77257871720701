import { ActivityLogType } from "./type";

//discount intial state
const initialAuthState = {
  error: null,
  activityList: null,
  isLoading: true
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case ActivityLogType.ACTIVITY_LOG_REQUEST:
      return { ...state, isLoading: true };
    case ActivityLogType.ACTIVITY_LOG_SUCCESS:
      return { ...state, isLoading: false, activityList: action.payload.data };
    case ActivityLogType.ACTIVITY_LOG_FAIL:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};
