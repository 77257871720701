export const EventType = {
  STORE_TICKET_DATA: "STORE_TICKET_DATA",
  CREATE_TICKET_REQUEST: "CREATE_TICKET_REQUEST",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_FAIL: "CREATE_TICKET_FAIL",
  UPDATE_TICKET_REQUEST: "UPDATE_TICKET_REQUEST",
  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_FAIL: "UPDATE_TICKET_FAIL",
  UPDATE_TICKET_STATUS_REQUEST: "UPDATE_TICKET_STATUS_REQUEST",
  UPDATE_TICKET_STATUS_SUCCESS: "UPDATE_TICKET_STATUS_SUCCESS",
  UPDATE_TICKET_STATUS_FAIL: "UPDATE_TICKET_STATUS_FAIL",
  GET_AGE_GROUP_REQUEST: "GET_AGE_GROUP_REQUEST",
  GET_AGE_GROUP_SUCCESS: "GET_AGE_GROUP_SUCCESS",
  GET_AGE_GROUP_FAIL: "GET_AGE_GROUP_FAIL",
  GET_ORGANIZER_DROPDOWN_REQUEST: "GET_ORGANIZER_DROPDOWN_REQUEST",
  GET_ORGANIZER_DROPDOWN_SUCCESS: "GET_ORGANIZER_DROPDOWN_SUCCESS",
  GET_ORGANIZER_DROPDOWN_FAIL: "GET_ORGANIZER_DROPDOWN_FAIL",
  DELETE_SUB_SLOT_REQUEST: "DELETE_SUB_SLOT_REQUEST",
  DELETE_SUB_SLOT_SUCCESS: "DELETE_SUB_SLOT_SUCCESS",
  DELETE_SUB_SLOT_FAIL: "DELETE_SUB_SLOT_FAIL",
  CHANGE_TICKET_ORDER_REQUEST: "CHANGE_TICKET_ORDER_REQUEST",
  CHANGE_TICKET_ORDER_SUCCESS: "CHANGE_TICKET_ORDER_SUCCESS",
  CHANGE_TICKET_ORDER_FAIL: "CHANGE_TICKET_ORDER_FAIL",
  SYNC_WITH_SEAT_IO_REQUEST: "SYNC_WITH_SEAT_IO_REQUEST",
  SYNC_WITH_SEAT_IO_SUCCESS: "SYNC_WITH_SEAT_IO_SUCCESS",
  SYNC_WITH_SEAT_IO_FAIL: "SYNC_WITH_SEAT_IO_FAIL"
};
